const links = {
  discord: 'https://discord.gg/R9u43zYZka',
  twitter: 'https://twitter.com/TokelPlatform',
  github: 'https://github.com/TokelPlatform/',
  github_dapp: 'https://github.com/TokelPlatform/tokel_dapp',
  github_chain: 'https://github.com/TokelPlatform/komodo',
  github_release_page: 'https://github.com/TokelPlatform/tokel_app/releases',
  github_nspv: 'https://github.com/TokelPlatform/nspv-js',
  openseaImage: 'https://opensea.io/assets/0xc2c747e0f7004f9e8817db2ca4997657a7746928/8064',
  superrareImage: 'https://superrare.co/artwork-v2/cochleanaut-18746',
  donate: 'https://discord.gg/R9u43zYZka',
  whitepaper: '/TokelPaper1stEdition.pdf',
  explorer: 'https://explorer.tokel.io/',
  bitcoinTalk: 'https://bitcointalk.org/index.php?topic=5352009.msg57581170#msg57581170',
  medium: 'https://medium.com/@tokel-platform/',
  documentationChain: 'https://docs.tokel.io/',
  documentationCreateTokenNFT:
    'https://docs.tokel.io/guides/CreateAToken/#how-to-create-a-token-or-nft',
  reddit: 'https://www.reddit.com/r/tokel/',
  youtube: 'https://www.youtube.com/channel/UCW9C_EuW54yOFArqJjRMJmQ',
  flote: 'https://flote.app/user/Tokel',
  facebook: 'https://www.facebook.com/TokelPlatform/',
  bittube: 'https://bittube.tv/profile/Tokel',
  telegram: 'https://t.me/TokelPlatformchat',
  nspv: 'https://developers.komodoplatform.com/basic-docs/smart-chains/smart-chain-setup/nspv.html#introduction',
  nspvApi: 'https://github.com/TokelPlatform/nspv-js/blob/development/API.md',
  atomicDex: 'https://atomicdex.io/',
  idoLiveStream: 'https://www.youtube.com/watch?v=WQ39eTAgdB0',
  aDEXStepByStep: 'https://docs.tokel.io/guides/HowToUseAtomicDEX/',
  aDexBuyTKLVideo: 'https://www.youtube.com/watch?v=fUs2l8kkBRQ',
  ca333interview: 'https://youtu.be/-sZc7DHcuI8',
  mediumEarlyAdopters:
    'https://medium.com/@tokel-platform/tokel-early-adopter-initiative-time-lock-reward-plan-9806f4ffc74',
  apple_podcasts: 'https://podcasts.apple.com/us/podcast/tokel-talk/id1598762562',
  tokenCreationTool: 'https://www.youtube.com/embed/dZikw30vO2U',
  dexTrade: 'https://dex-trade.com/refcode/uk7ztz',
  spotify: 'https://open.spotify.com/show/7oe8Wt6gz3dMDnYYkbRhsG',
  deezer: 'https://www.deezer.com/en/show/3156892',
  stitcher: 'https://www.stitcher.com/show/tokel-talk',
  googlePodcasts:
    'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xODg3ODA1LnJzcw==',
  amazonMusic: 'https://music.amazon.com/podcasts/efe0404f-76f8-499b-9fd0-d95dfa403161/tokel-talk',
  applePodcasts: 'https://podcasts.apple.com/fr/podcast/tokel-talk/id1598762562',
  instagram: 'https://instagram.com/TokelPlatform',
  dexTradeTutorials: {
    signup: 'https://www.youtube.com/watch?v=_xEUhYNDYe0',
    deposit: 'https://www.youtube.com/watch?v=B1ZylW2SM4Q',
  },
  downloads: {
    linux:
      'https://github.com/TokelPlatform/tokel_dapp/releases/download/latest/LINUX-TokelPlatform-1.2.1.AppImage',
    win: 'https://github.com/TokelPlatform/tokel_dapp/releases/download/latest/WINDOWS-TokelPlatform-Setup-1.2.1.exe',
    mac: 'https://github.com/TokelPlatform/tokel_dapp/releases/download/latest/MAC-TokelPlatform-1.2.1.dmg',
  },
  tokelistArticle:
    'https://medium.com/@tokel-platform/what-is-a-tokelist-how-to-become-one-855ca6686494',
  createTokelWallet: 'https://www.youtube.com/watch?v=kYiV41XeWfg',
  nftPodcastTop: 'https://www.bcast.fm/blog/best-nft-podcasts',
  komodo: 'https://komodoplatform.com/',
  mailContact: 'mailto:contact@tokel.io',
  mailSupport: 'mailto:support@tokel.io',
  exploreTokel: 'https://exploretokel.com',
};

export const podcasts = {
  spotify: links.spotify,
  deezer: links.deezer,
  stitcher: links.stitcher,
  googlePodcasts: links.googlePodcasts,
  amazonMusic: links.amazonMusic,
  applePodcasts: links.applePodcasts,
};
export default links;
